/* eslint-disable */
function importJQ () {
    var num = 1
  $(".btn_menuck").click(function () {
    num = num + 1;
    if (num == 2) {
        $(".open_nav").css("right", "0");
        $(".btn_pic01").css("display", "none");
        $(".btn_pic02").css("display", "block");

        num = num - 2
    }
    else {
        $(".open_nav").css("right", "-120%");
        $(".btn_pic01").css("display", "block");
        $(".btn_pic02").css("display", "none");
    }

});

/*info提示框*/

 $(".subtit img").hover(function () {
    $(".dialog").toggleClass("dialogBlock");
    });

  $(".solarImg").hover(function () {
    $(".solardialog").toggleClass("solardialogBlock");
  });
  
  $(".solarImg1").hover(function () {
    $(".solardialog1").toggleClass("solardialogBlock1");
  });

/*footer置底*/
var $win = $(window);
$win.bind('resize', function () {
    try {
        $('.contain').css("min-height", $win.height() - $('footer').outerHeight() - $('.contain').offset().top);
    } catch (err) { }
}).resize();

}
export default importJQ

